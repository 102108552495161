import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['stopPrice', 'stopPriceWrapper', 'limitPrice', 'limitPriceWrapper', 'bidAskSpreadsWrapper', 'positionId', 'orderType', 'buySell']

  toggleOrderType() {
    if (this.orderTypeTarget.value === 'limit_order') {
      this.limitPriceWrapperTarget.classList.remove('d-none')
      this.stopPriceWrapperTarget.classList.add('d-none')
    } else if (this.orderTypeTarget.value === 'market_order') {
      this.limitPriceWrapperTarget.classList.add('d-none')
      this.stopPriceWrapperTarget.classList.add('d-none')
    } else if (this.orderTypeTarget.value === 'stop_order') {
      this.limitPriceWrapperTarget.classList.add('d-none')
      this.stopPriceWrapperTarget.classList.remove('d-none')
    } else if (this.orderTypeTarget.value === 'stop_limit_order') {
      this.limitPriceWrapperTarget.classList.remove('d-none')
      this.stopPriceWrapperTarget.classList.remove('d-none')
    }

    this.computeConstraints()
  }

  toggleBuySell() {
    this.computeConstraints()
  }

  togglePosition() {
    let bidAskSpreadId = 'bid_ask_spread_' + this.positionIdTarget.value

    this.bidAskSpreadsWrapperTarget.querySelectorAll('.bid-ask-spread').forEach(bidAskSpread => {
      if (bidAskSpread.id === bidAskSpreadId) {
        bidAskSpread.classList.remove('d-none')
      } else {
        bidAskSpread.classList.add('d-none')
      }
    })
    
    if (this.bidAskSpreadsWrapperTarget.querySelector('#' + bidAskSpreadId).getAttribute('data-auction') === 'true') {
      this.orderTypeTarget.querySelectorAll('option[value="market_order"]').forEach(option => option.disabled = true)
      this.orderTypeTarget.querySelectorAll('option[value="stop_order"]').forEach(option => option.disabled = true)
      this.orderTypeTarget.querySelectorAll('option[value="stop_limit_order"]').forEach(option => option.disabled = true)
    } else {
      this.orderTypeTarget.querySelectorAll('option[value="market_order"]').forEach(option => option.disabled = false)
      this.orderTypeTarget.querySelectorAll('option[value="stop_order"]').forEach(option => option.disabled = false)
      this.orderTypeTarget.querySelectorAll('option[value="stop_limit_order"]').forEach(option => option.disabled = false)
    }

    this.computeConstraints()
  }

  bidAskSpreadForPosition(positionId) {
    let target = this.bidAskSpreadsWrapperTarget.querySelector('#bid_ask_spread_' + positionId)

    let bidText = target.querySelector('.bid-value').innerText
    let askText = target.querySelector('.ask-value').innerText

    let bid = bidText.split('℘')[1]
    let ask = askText.split('℘')[1]

    return [bid, ask]
  }

  computeConstraints() {
    if (this.orderTypeTarget.value === 'limit_order') {
      this.computeLimitOrderConstraints()
    } else if (this.orderTypeTarget.value === 'market_order') {
      this.computeMarketOrderConstraints()
    } else if (this.orderTypeTarget.value === 'stop_order') {
      this.computeStopOrderConstraints()
    } else if (this.orderTypeTarget.value === 'stop_limit_order') {
      this.computeStopLimitOrderConstraints()
    }
  }

  computeLimitOrderConstraints(nullStops = true) {
    if (nullStops) {
      this.stopPriceTarget.value = null
      this.stopPriceTarget.removeAttribute('min')
      this.stopPriceTarget.removeAttribute('max')
    }

    let limitPriceAtLeast = this.limitPriceWrapperTarget.querySelector('.limit-price-at-least')
    let limitPriceAtMost  = this.limitPriceWrapperTarget.querySelector('.limit-price-at-most')
    let limitPriceMin     = this.limitPriceWrapperTarget.querySelector('.limit-price-min')
    let limitPriceMax     = this.limitPriceWrapperTarget.querySelector('.limit-price-max')

    if (this.positionIdTarget.value) {
      let [bid, ask] = this.bidAskSpreadForPosition(this.positionIdTarget.value)

      if (this.buySellTarget.value === 'true') {
        limitPriceAtLeast.classList.add('d-none')
        limitPriceMin.innerHTML = ''

        this.limitPriceTarget.setAttribute('min', 0.0001)

        if (ask) {
          this.limitPriceTarget.setAttribute('max', ask)

          limitPriceAtMost.classList.remove('d-none')
          limitPriceMax.innerHTML = '℘' + ask
        } else {
          this.limitPriceTarget.removeAttribute('max')

          limitPriceAtMost.classList.add('d-none')
          limitPriceMax.innerHTML = ''
        }
      } else if (this.buySellTarget.value === 'false') {
        limitPriceAtMost.classList.add('d-none')
        limitPriceMax.innerHTML = ''

        this.limitPriceTarget.removeAttribute('max')

        if (bid) {
          this.limitPriceTarget.setAttribute('min', bid)

          limitPriceAtLeast.classList.remove('d-none')
          limitPriceMin.innerHTML = '℘' + bid
        } else {
          this.limitPriceTarget.setAttribute('min', 0.0001)

          limitPriceAtLeast.classList.add('d-none')
          limitPriceMin.innerHTML = ''
        }
      }
    }
  }

  computeMarketOrderConstraints() {
    this.limitPriceTarget.value = null
    this.limitPriceTarget.removeAttribute('min')
    this.limitPriceTarget.removeAttribute('max')

    this.stopPriceTarget.value = null
    this.stopPriceTarget.removeAttribute('min')
    this.stopPriceTarget.removeAttribute('max')
  }

  computeStopOrderConstraints(nullLimits = true) {
    if (nullLimits) {
      this.limitPriceTarget.value = null
      this.limitPriceTarget.removeAttribute('min')
      this.limitPriceTarget.removeAttribute('max')
    }

    let stopPriceAtLeast = this.stopPriceWrapperTarget.querySelector('.stop-price-at-least')
    let stopPriceAtMost  = this.stopPriceWrapperTarget.querySelector('.stop-price-at-most')
    let stopPriceMin     = this.stopPriceWrapperTarget.querySelector('.stop-price-min')
    let stopPriceMax     = this.stopPriceWrapperTarget.querySelector('.stop-price-max')

    if (this.positionIdTarget.value) {
      let [bid, ask] = this.bidAskSpreadForPosition(this.positionIdTarget.value)

      if (this.buySellTarget.value === 'true') {
        stopPriceAtLeast.classList.add('d-none')
        stopPriceMin.innerHTML = ''

        this.stopPriceTarget.setAttribute('min', 0.0001)

        if (ask) {
          this.stopPriceTarget.setAttribute('max', ask)

          stopPriceAtMost.classList.remove('d-none')
          stopPriceMax.innerHTML = '℘' + ask
        } else {
          this.stopPriceTarget.removeAttribute('max')

          stopPriceAtMost.classList.add('d-none')
          stopPriceMax.innerHTML = ''
        }
      } else if (this.buySellTarget.value === 'false') {
        stopPriceAtMost.classList.add('d-none')
        stopPriceMax.innerHTML = ''

        this.stopPriceTarget.removeAttribute('max')

        if (bid) {
          this.stopPriceTarget.setAttribute('min', bid)

          stopPriceAtLeast.classList.remove('d-none')
          stopPriceMin.innerHTML = '℘' + bid
        } else {
          this.stopPriceTarget.setAttribute('min', 0.0001)

          stopPriceAtLeast.classList.add('d-none')
          stopPriceMin.innerHTML = ''
        }
      }
    }
  }

  computeStopLimitOrderConstraints() {
    this.computeLimitOrderConstraints()
    this.computeStopOrderConstraints(false)
  }
}
