import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  chartkickChart = null
  chartjsChart   = null

  static values  = { assetId: String }

  connect() {
    this.chartkickChart = Chartkick.charts['order-chart-' + this.assetIdValue]
    this.chartjsChart   = this.chartkickChart.getChartObject()

    this.chartjsChart.options.plugins.tooltip.callbacks.title = (context) => {
      let datasetIndex = context[0].datasetIndex
      let dataRowIndex = context[0].dataIndex
      let dataRow      = this.chartkickChart.rawData[datasetIndex].data[dataRowIndex]

      return (dataRow[3] === null || dataRow[4] === null) ? context[0].dataset.label : context[0].dataset.label + ' (' + dataRow[3] + ', ' + dataRow[4] + ')'
    }

    this.chartjsChart.options.plugins.tooltip.callbacks.label = (context) => {
      let datasetIndex = context.datasetIndex
      let dataRowIndex = context.dataIndex
      let dataRow      = this.chartkickChart.rawData[datasetIndex].data[dataRowIndex]

      return (dataRow[3] === null || dataRow[4] === null) ? null : '' + dataRow[2] + ' @ ℘' + dataRow[1]
    }
    
    this.chartjsChart.options.plugins.tooltip.callbacks.usePointStyle = true

    this.chartjsChart.options.plugins.tooltip.callbacks.labelPointStyle = (context) => {
      let datasetIndex = context.datasetIndex
      let dataRowIndex = context.dataIndex
      let dataRow      = this.chartkickChart.rawData[datasetIndex].data[dataRowIndex]

      return (dataRow[4] !== 'active' && dataRow[4] !== 'stopped') ?  'triangle' : 'circle'
    }

    this.chartjsChart.update()
  }

  setStyles() {
    let options = this.chartjsChart.getOptions()

    let tooltipCallbacks = {
      title: (tooltipItem, data) => {
        let dataset    = this.chartjsChart.rawData[tooltipItem[0].datasetIndex]
        let datasetRow = dataset.data[tooltipItem[0].index]

        return dataset.name + ' (' + datasetRow[3] + ', ' + datasetRow[4] + ')'
      },
      label: (tooltipItem, data) => {
        let datasetRow = this.chartjsChart.rawData[tooltipItem.datasetIndex].data[tooltipItem.index]

        return '' + datasetRow[2] + ' @ ℘' + datasetRow[1]
      },
      labelPointStyle: (tooltipItem) => {
        let datasetRow = this.chartjsChart.rawData[tooltipItem.datasetIndex].data[tooltipItem.index]

        let shape = 'circle'

        if (datasetRow[4] !== 'active' && datasetRow[4] !== 'stopped') {
          shape = 'triangle'
        }

        return shape
      }
    }
  
    if (!options.hasOwnProperty('library')) {
      options.library = {}
    }

    if (!options.library.hasOwnProperty('plugins')) {
      options.library.plugins = {}
    }

    options.library.plugins.tooltip = tooltipCallbacks

    this.chartjsChart.setOptions(options)
  }
}
