import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['exchangeTo', 'exchangeToPositive', 'exchangeToNegative', 'quantity'];

  togglePositiveNegative() {
    if (this.exchangeToTarget.value === 'true') {
      this.quantityTarget.setAttribute('min', 0);
      this.quantityTarget.removeAttribute('max');
      this.exchangeToPositiveTarget.classList.remove('d-none');
      this.exchangeToNegativeTarget.classList.add('d-none');
    } else {
      this.quantityTarget.removeAttribute('min');
      this.quantityTarget.setAttribute('max', 0);
      this.exchangeToPositiveTarget.classList.add('d-none');
      this.exchangeToNegativeTarget.classList.remove('d-none');
    }
  }
}
