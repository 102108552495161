import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  tag(event) {
    event.preventDefault()

    let tagId = event.target.getAttribute('data-tag-id')

    this.element.querySelector('[name="tagging[tag_id]"]').value = tagId

    this.element.submit()
  }
}
